import Axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import { ModalProgrammatic as Modal } from 'buefy';
import SessionExpired from '../components/SessionExpired.vue';
import Storage from '@/plugins/storage';

export const AUTH_HEADER = 'Authorization';

export default class API {
    protected baseURL: string;
    protected client: AxiosInstance;

    constructor(baseURL: string) {
        this.baseURL = baseURL;

        this.client = Axios.create({
            baseURL,
        });
    }

    public url(uri: string): string {
        return this.baseURL + uri;
    }

    public setBearer(token: string) {
        this.client.defaults.headers.common[AUTH_HEADER] = `Bearer ${token}`;
    }

    public forgetBearer() {
        delete this.client.defaults.headers.common[AUTH_HEADER];
    }

    protected apiCall(promise: Promise<AxiosResponse<any>>): Promise<any> {
        return new Promise((resolve, reject) => {
            promise
                .then((res: any) => resolve(res.data))
                .catch((err: AxiosError) => {
                    reject(err);

                    if (err.response?.status === 401) {
                      const reqURL = new URL(err.request.responseURL);
                      if (reqURL.pathname !== '/auth/login' && reqURL.pathname !== '/merchant/auth/login') {
                        Modal.open({
                          component: SessionExpired,
                          onCancel: () => {
                            Storage.remove('merchant_access_token');
                            window.location.href = '/';
                          }
                        });
                      }
                    }
                });
        });
    }
}
