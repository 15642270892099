import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';

const debug = process.env.NODE_ENV !== 'production';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
  },
  mutations: {
    setLoading(state: any, loading: boolean) {
      state.isLoading = loading;
    },
  },
  state: {
    isLoading: true,
  },
  getters: {
    isLoading: (state: any) => state.isLoading,
  },
  actions: {
    setLoading({commit}: {commit: any}, loading: boolean) {
      commit('setLoading', loading);
    },
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
});
