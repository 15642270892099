import Cookies from 'js-cookie';

class IdleTimer {
  public static readonly CookieKey = 'session_idle_expired';

  // in milliseconds
  private readonly timeout: number;
  private readonly onTimeout: () => void;
  private intervalId: number | null = null;

  public constructor(timeout: number, onTimeout: () => void) {
    this.timeout = timeout;
    this.onTimeout  = onTimeout;

    this.tracker();
    this.interval();
  }

  private tracker() {
    const handler = this.eventHandler(this.timeout);

    handler();
    window.addEventListener('mousemove', handler);
    window.addEventListener('scroll', handler);
    window.addEventListener('keypress', handler);
  }

  private eventHandler(timeout: number): () => void {
    return () => {
      const now = new Date();
      now.setSeconds(now.getSeconds() + timeout)

      Cookies.set(IdleTimer.CookieKey, 'exists', {
        expires: now,
      });
    }
  }

  private interval() {
    this.intervalId = setInterval(() => {
      if (!IdleTimer.exists()) {
        this.onTimeout();
      }
    }, 5000);
  }

  public static exists(): boolean {
    return Cookies.get(IdleTimer.CookieKey) !== undefined;
  }

  public destroy() {
    Cookies.remove(IdleTimer.CookieKey);

    if ((this.intervalId !== null)) {
      clearInterval(this.intervalId);
    }
  }
}

export default IdleTimer;
