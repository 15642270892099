import * as types from '../../mutation-types';
import MerchantAPI from '@/api/merchant';
import User from '@/models/user';
import Storage from '@/plugins/storage';
import IdleTimer from '@/components/IdleTimer';
import {ModalProgrammatic as Modal} from 'buefy';
import SessionExpired from '@/components/SessionExpired.vue';

export const STORAGE_AUTH_TOKEN = 'merchant_access_token';

interface AuthState {
    user: User | null,
    token: string | null,
    idler: IdleTimer | null,
}

const IdleTimeout = 15 * 60; // in seconds

export default {
    namespaced: true,
    mutations: {
        [types.AUTH_LOGIN](state: any, user: User) {
            state.user = user;
        },
        [types.AUTH_LOGOUT](state: any) {
            state.user = null;
            state.token = null;
        },
        [types.AUTH_IDLER](state: any, idler: IdleTimer) {
            state.idler = idler;
        },
    },
    actions: {
        login: ({commit, dispatch}: {commit: any, dispatch: any}, credential: object): Promise<any> => {
            return new Promise(((resolve, reject) => {
                MerchantAPI
                    .login(credential)
                    .then(({api_token, user}: {api_token: string, user: User}) => {
                        dispatch('fetched', {user, accessToken: api_token});
                        resolve(user);
                    })
                    .catch((err: any) => reject(err));
            }));
        },
        logout: ({commit, state}: {commit: any, state: AuthState}) => {
            // TODO: query on server

            if (state.idler !== null) {
                state.idler.destroy();
            }

            Storage.remove(STORAGE_AUTH_TOKEN);
            commit(types.AUTH_LOGOUT);
        },
        fetched: ({commit, dispatch}: {commit: any, dispatch: any}, {user, accessToken}: {user: User, accessToken: string}) => {
            MerchantAPI.setBearer(accessToken);
            Storage.set(STORAGE_AUTH_TOKEN, accessToken);

            const onTimeoutHandler = () => {
                dispatch('logout')
                  .then(() => {
                      Modal.open({
                          component: SessionExpired,
                          onCancel: () => {
                              window.location.href = '/';
                          }
                      });
                  })
                  .catch((err: any) => alert(err));
            }

            commit(types.AUTH_IDLER, new IdleTimer(IdleTimeout, onTimeoutHandler));
            commit(types.AUTH_LOGIN, new User(user));
        },
    },
    state: {
        user: null,
        token: null,
        idler: null,
    },
    getters: {
        token: (state: any) => state.token,
        user: (state: any) => state.user,
        check: (state: any) => state.user !== null,
    },
};
