


























// import Vue from 'vue';
// import Component from 'vue-class-component';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LangSelector extends Vue {
  @Prop({default: false}) small!: boolean;

  public setLocale(locale: string) {
      this.$i18n.locale = locale;
      localStorage.setItem('lang', locale);
  }

  // public mounted() {
  //   this.$i18n.locale = localStorage.getItem('lang') || 'ru';
  // }
}
