/**
 * Authenticate
 *
 * @param next
 * @param store
 */
export default ({ next, store }: {next: any, store: any}) => {
  if (!store.getters['auth/check']) {
    return next({
      name: 'Login',
    });
  }

  return next();
};
