import GatewayAPI from './gateway';
import MerchantAPI from './merchant';

export class ErrorAPI {
    private errors: Map<string, string[]> = new Map();

    public put(errors: Map<string, string[]>): void {
        this.clear();

        for (const [key, value] of Object.entries(errors)) {
            this.push(key, value);
        }
    }

    public push(key: string, value: string[]): void {
        this.errors.set(key, value);
    }

    public has(key: string): boolean {
        return this.errors.has(key);
    }

    public length(key: string): number {
        return this.get(key).length;
    }

    public isDanger(key: string): string {
        return this.length(key) ? 'is-danger' : '';
    }

    public clear(): void {
        this.errors = new Map();
    }

    public get(key: string): string[] {
        if (this.errors.has(key)) {
            const errors = this.errors.get(key);
            if (errors !== undefined) {
                return errors;
            }
        }

        return [];
    }

    public join(key: string, separator: string = '|'): string {
        return this.get(key).join(` ${separator} `);
    }

    public static parse(errors: ErrorAPI) {
        return (err: any) => {
            if (err.response === undefined) {
                errors.push('default', ['Problem with server try later']);
                return;
            }

            switch (err.response.status) {
                case 401:
                    errors.push('email', [err.response.data.message] || []);
                    break;
                case 422:
                    errors.put(err.response.data);
                    break;
                default:
                    errors.push('default', ['Problem with server try later']);
            }
        }
    }
}

export {
    GatewayAPI,
    MerchantAPI,
};
