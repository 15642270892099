import Cookies from 'js-cookie';

export default class Storage {
    public static has(key: string): boolean {
        return !!Cookies.get(key);
    }

    public static get(key: string): string | undefined {
        return Cookies.get(key);
    }

    public static set(key: string, value: string | object): string | undefined {
        return Cookies.set(key, value);
    }

    public static remove(key: string): void {
        return Cookies.remove(key);
    }

    public static language() {
        return localStorage.getItem('lang') || 'ru';
    }
}
