




































import Vue from 'vue';
import Component from 'vue-class-component';
import Navigator from '@/components/Navigator.vue';
import Menu from '@/components/Menu.vue';
import User from '@/models/user';
import {MerchantAPI} from '@/api';

@Component({
  components: {
    Navigator,
    Menu,
  },
})

export default class App extends Vue {
  private loading = false;

  public authenticated(): boolean {
    return this.$store.getters['auth/check'];
  }

  public user(): User | null {
    return this.$store.getters['auth/user'];
  }

  public userEmailNotVerified(): boolean {
    const user = this.user();

    return user === null || !user.hasVerifiedEmail();
  }

  public resend() {
    const user = this.user();
    if (user === null) {
      return;
    }
    this.loading = true;

    MerchantAPI
        .emailResend(user.email)
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t('ReciverSentMsg').toString(),
            position: 'is-bottom',
            type: 'is-success',
          });
        })
        .catch((err: any) => {
          this.$buefy.toast.open({
            message: err.message,
            position: 'is-bottom',
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.loading = false;
        });
  }

  public mounted() {
    this.$i18n.locale = localStorage.getItem('lang') || 'ru';
  }
}
