import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';
import store from './store';
import { loadUser } from '@/plugins/helpers';
import User from '@/models/user';
import VueMask from 'v-mask';
// i18n
import i18n from './i18n/i18n';

Vue.use(Buefy);
Vue.use(VueMask);
Vue.config.productionTip = false;

// @ts-ignore
loadUser().then(async ({user, accessToken}: {user?: User, accessToken: string}) => {
  if (user !== null) {
    await store.dispatch('auth/fetched', {user, accessToken});
  }
}).catch((err) => {
  if (err.response !== undefined && err.response.status === 401) {
    store.dispatch('auth/logout')
        .then(() => router.push('/'))
        .catch((e: any) => alert(e));
  }

  // tslint:disable-next-line
  console.info(err.message);
}).finally(() => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
});
