












import Vue from 'vue';
import Component from 'vue-class-component';
import User from '../models/user';

@Component
export default class Menu extends Vue {

  private user(): User | null {
    return this.$store.getters['auth/user'];
  }

  public userEmailVerified(): boolean {
    const user = this.user();

    return user !== null && user.hasVerifiedEmail();
  }

  public userPayout(): boolean {
    const user = this.user();

    return user !== null && user.payout;
  }
}
