import Storage from '@/plugins/storage';
import {STORAGE_AUTH_TOKEN} from '@/store/modules/auth';
import {AUTH_HEADER} from '@/api/api';
import User from '@/models/user';
import IdleTimer from '@/components/IdleTimer';

export function loadUser() {
    return new Promise((resolve, reject) => {
        if (!Storage.has(STORAGE_AUTH_TOKEN)) {
            reject(Error('No accessToken into storage'));
            return;
        }

        const accessToken = Storage.get(STORAGE_AUTH_TOKEN);

        if (!IdleTimer.exists()) {
            Storage.remove(STORAGE_AUTH_TOKEN);
            reject(Error('Idler expired'));
        }

        // @ts-ignore
        fetch(Platform.VUE_APP_API_MERCHANT_BASE_URL + 'user', {
            headers: {
                [AUTH_HEADER]: `Bearer ${accessToken}`,
            },
        }).then(async (res) => {
            const user: User = await res.json();
            resolve({user, accessToken});
        }).catch(() => {
            reject(Error('Fail user fetch'));
        });
    });
}

export function getBaseDomain() {
    var parts = location.hostname.split('.');
    parts.shift();

    return parts.join('.');
}
