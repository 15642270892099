import { ToastProgrammatic as Toast } from 'buefy'

/**
 * Verified
 *
 * @param next
 * @param from
 * @param store
 */
export default ({ next, from, store}: {next: any, from: any, store: any}) => {
    if (!store.getters['auth/user'].hasVerifiedEmail()) {
        // Toast.open({
        //     message: Vue.$t('EmailNeedToVerify'),
        //     position: 'is-bottom',
        //     type: 'is-danger',
        // });

        if (from.name !== 'Dashboard') {
            return next({
                name: 'Dashboard',
            });
        }

        return false;
    }

    return next();
};
