export default {
  ru: {
    Validation: {
      'Required': 'Пожалуйста, заполните это поле.',
      'IncorrectBIN': 'Пожалуйста, введите БИН, допускается только число.',
      'IncorrectIIK': 'Пожалуйста, введите ИИК, допускается цифры и заглавные латинские буквы.',
      'IncorrectBIK': 'Пожалуйста, введите БИК, допускается только заглавные латинские буквы',
      'EnterTitle': 'Введите название, пожалуйста',
      'EnterHostname': 'Введите имя хоста, пожалуйста',
      'EnterServerUrl': 'Введите URL сервера, пожалуйста',
      'EnterSuccessUrl': 'Введите URL обработчика успешной операции, пожалуйста',
      'EnterFailUrl': 'Введите URL обработчика ошибок, пожалуйста',
      'EnterNetworks': 'Укажите разрешенные сети, пожалуйста',
      'EnterPCommission': 'Введите ставку процентной комиссии, пожалуйста. (только цифры и символ точки)',
      'EnterFCommission': 'Введите ставку фиксированной комиссии, пожалуйста. (только цифры и символ точки)',
      'EnterPrivate': 'Введите значение закрытого ключа, пожалуйста',
      'EnterOrderId': 'Введите ID заказа, пожалуйста',
      'EnterAmount': 'Введите сумму платежа, пожалуйста. (только цифры и символ точки)',
      'ChooseCurrency': 'Выберите валюту',
      'EnterDescr': 'Введите описание, пожалуйста.',
      'EnterEmail': 'Введите email',
      'EnterName': 'Введите Ваше имя',
      'EnterPhone': 'Введите номер телефона',
      'EnterPwd': 'Введите пароль',
      'EnterPwdConfirm': 'Введите подтверждение пароля',
    },
    'LogIn': 'Войти',
    'Logout': 'Выход',
    'AuthHdr': 'Platform: Кабинет продавца',
    'EmailLbl': 'E-mail',
    'PwdLbl': 'Пароль',
    'PwdCLbl': 'Подтверждение пароля',
    'EnterEmailLbl': 'Введите адрес email',
    'EnterPwdLbl': 'Введите пароль',
    'EnterPwdConfirmLbl': 'Введите подтверждение пароля',
    'EmailValidateMsg': 'Введите правильный адрес email, пожалуйста',
    'RememberMe': 'Запомнить меня',
    'Register': 'Регистрация',
    'Login': 'Вход',
    'ForgotPwdBtn': 'Забыли пароль?',
    'SrvPoblemMsg': 'Наблюдаются проблемы с сервером. Попробуйте позже',
    'NameLbl': 'Имя',
    'PhoneLbl': 'Номер телефона',
    'SendEmailBtn': 'Отправить на почту',
    'IRememberedCB': 'Я вспомнил свой пароль!',
    'ReciverSentMsg': 'Запрос отправлен. Проверьте свою почту',
    'BackBtn': 'Назад',
    'EmailNeedToVerify': 'Вам необходимо подтвердить Ваш Email',
    'PageNotFound': 'Страница не найдена',
    'PageNotExists': 'Запрашиваемая страница не существует или была перемещена или удалена. Пожалуйста, свяжитесь с владельцем сайта.',
    'GoToLogin': 'Перейти к странице Авторизации',
    'App': 'Приложение',
    'Apps': 'Приложения',
    'PublicKey': 'Открытый ключ',
    'PrivateKey': 'Закрытый ключ',
    'OrderId': 'ID заказа',
    'Amount': 'Сумма',
    'Currency': 'Валюта',
    'CurrencyPH': 'Выберите валюту',
    'Description': 'Описание',
    'ErrorDescription': 'Описание ошибки',
    'AppRedirectCB': 'Перенаправить после создания',
    'NewPaymentType': 'Использовать новый метод оплаты',
    'Back': 'Назад',
    'UsePayout': 'Разрешить выплаты',
    'PayBtn': 'Оплатить',
    'PayOutBtn': 'Вывести',
    'Title': 'Заголовок',
    'Hostname': 'Имя хоста',
    'ServerURL': 'URL сервера',
    'ReturnURL': 'URL для возврата',
    'SuccessURL': 'URL успешности',
    'FailedURL': 'URL ошибки',
    'TrustCreateKey': 'Я доверяю Вам создать мой ключ',
    'DontTrustCreateKey': 'Я <b>не</b> доверяю Вам и <a href="https://libsodium.gitbook.io/doc/bindings_for_other_languages" target="_blank">создам</a> ключ самостоятельно.',
    'AllowedNetworks': 'Разрешенные сети',
    'UseIPMsg': 'Используйте IP-адрес вида <b>192.168.1.1</b> или с маской сети <b>192.168.1.0/24</b>, разделяя адреса запятой. Чтобы разрешить все, используйте символ <b>*</b>.',
    'CommissionPercent': 'Процентная комиссия',
    'FixedCommission': 'Фиксированная комиссия',
    'UseTesting': 'Использовать тестовый режим',
    'KeysDialogHdr': '<b>Внимание!</b> Не закрывайте это окно сразу!',
    'KeysDialogLine1': 'Поскольку ключи шифрования были созданы на сервере, они НЕ ХРАНЯТСЯ НИГДЕ.',
    'KeysDialogLine2': 'Обязательно сохраните их перед закрытием окна.',
    'Address': 'Адрес',
    'BIN': 'БИН',
    'IIK': 'ИИК',
    'BIK': 'БИК',
    'Update': 'Обновить',
    'Create': 'Создать',
    'Size': 'Размер',
    'Actions': 'Действия',
    'AddFilesErrMsg': 'Вы можете добавлять файлы только для созданной ранее Организации',
    'Documents': 'Документы',
    'AddDocs': 'Добавыить документы',
    'MaxFilesMsg': 'Достигнуто максимальное количество файлов',
    'PrivateKeyError': 'Неправильный закрытый ключ',
    'AppUpdatedMsg': 'Приложение обновлено',
    'IsNotValidUrl': 'Это неправильный URL',
    'EmptyNetworks': 'Не указаны разрешенные сети',
    'InvalidDataFormat': 'неправильный формат данных',
    'OrganizationCreated': 'Огранизация создана',
    'NetworkError': 'Сетевая ошибка. Попробуйте позже.',
    'InvalidAmount': 'Баланс должен быть больше 0',
    'OrganizationUpdated': 'Организация обновлена',
    'FileNotSupported': 'Загруженный файл не поддерживается',
    'UplMaxSize': 'Загружен максимальный размер',
    'or': 'или',
    'UploadError': 'Ошибка загрузки: попробуйте позже.',
    'DeleteFileConfirm': 'Вы действительно хотите <b>удалить</b> этот документ? Это действие нельзя будет отменить.',
    'CanNotDeleteFile': 'Не получилось удалить файл',
    'DeletingDocument': 'Удаление документа',
    'Applications': 'Приложения',
    'NextPage': 'Следующая',
    'PreviousPage': 'Предыдущая',
    'Page': 'Страница',
    'OrderID': 'OrderID',
    'CurrentPage': 'Текущая',
    'AppTestingMode': 'Приложение в тестовом режиме',
    'Percent': 'Процент',
    'Fixed': 'Фикс.',
    'Balance': 'Баланс для выплат',
    'CardPan': 'Карта',
    'Edit': 'Изменить',
    'Delete': 'Удалить',
    'AppKey': 'Ключ приложения',
    'Copy': 'Копировать',
    'TryApp': 'Провести тестовый платеж',
    'TryPayout': 'Провести тестовую выплату',
    'RequestProdLbl': 'Запросить производственный режим',
    'RequestProdBtn': 'Запрос производственного режима',
    'ReplenishBalance': 'Пополнить баланс',
    'ReplenishBtn': 'Отправить запрос на пополнение баланса',
    'BalanceReplenished': 'Запрос на пополнение баланса отправлен на рассмотрение',
    'OrgDreateNeedMsg': 'Сначала Вам необходимо создать',
    'OrgA': 'Организацию',
    'Org': 'Организация',
    'NothingHere': 'Пусто',
    'AppKeyCopied': 'Ключ приложения скопирован',
    'OrgNotSet': 'Текущая организация не установлена',
    'RequestProdSent': 'Запрос на производственный режим был отправлен',
    'DeletingAppHdr': 'Удаление приложения',
    'DeletingAppMsg': 'Вы действительно хотите <b>удалить</b> это приложение? Это действие нельзя будет отменить.',
    'DeletingAppConfirm': 'Удаление приложения',
    'Dashboard': 'Dashboard',
    'StagesTitle': 'Этапы подачи заявки на партнерство',
    'FillOutAppFrm': 'Заполните форму заявления',
    'CreateAppIn': 'Создайте новое приложение в',
    'AppsSection': 'разделе Приложения',
    'CarryOutIntegr': 'Выполните интеграцию',
    'CheckOutOur': 'Изучите нашу',
    'APIDocs': 'API документацию',
    'MakeIntegr': 'Выполните интеграцию',
    'FillOutOrgFrm': 'Заполните форму Организации',
    'CreateOrgIn': 'Создайте новую организацию в',
    'OrgSection': 'разделе Организация',
    'ActivateProd': 'Активируйте производственный режим',
    'SendRequestOn': 'Чтобы отправить запрос на перевод Приложения в производственный режим, необходимо нажать на стрелку в',
    'SelectOrgForRequest': 'Выберите ранее созданную Организацию из списка и нажмите кнопку "Запрос производственного режима".',
    'AllApps': 'Все Приложения',
    'Deleted': 'Удалено',
    'NeedToVerifyEmail': 'Вам необходимо подтвержить Ваш email',
    'Success': 'Успешно',
    'Cancel': 'Отмена',
    'Fail': 'Сбой',
    'Errors': 'Ошибки',
    'EnailVFail': 'Ошибка верификации email',
    'VerifyExpired': 'Неправильная или просроченная подпись. Пожалуйста, запросите подтверждение снова.',
    'PaymentDetails': 'Детали платежа',
    'PaymentHash': 'Хэш платежа',
    'AcquirerId': 'ID Эквайера',
    'TerminalId': 'ID Терминала',
    'CardNumber': 'Номер карты',
    'CardholderName': 'Имя владельца',
    'Email': 'Email',
    'Phone': 'Телефон',
    'TotalAmount': 'Сумма итого',
    'TotalCommission': 'Комиссия итого',
    'Commissions': 'Комиссии',
    'CommissionType': 'Тип комиссии',
    'Inner': 'Внутр.',
    'Outer': 'Внешн.',
    'SystemCommission': 'Комиссия системы',
    'AppCommission': 'Комиссия Приложения',
    'Status': 'Статус',
    'AutoClearing': 'Авто-клиринг',
    'Yes': 'Да',
    'No': 'Нет',
    'Lang': 'Язык',
    'CreatedAt': 'Дата создания',
    'UpdatedAt': 'Дата обновления',
    'Payments': 'Прием платежей',
    'Payouts': 'Выплаты',
    'ClearFilter': 'Очистить фильтр',
    'Download': 'Скачать',
    'DownloadCSV': 'Скачать в формате .CSV',
    'DownloadXLSX': 'Скачать в формате .XLSX',
    'AllStatuses': 'Все статусы',
    'Created': 'Создан',
    'Canceled': 'Отменен',
    'InProcess': 'В обработке',
    'Authorized': 'Авторизован',
    'Completed': 'Завершен',
    'Reversed': 'Reversed',
    'Refunded': 'Возвращен',
    'CreatedFrom': 'Создан с...',
    'CreatedTo': '... по',
    'Detail': 'Подробнее',
    'Hash': 'Хэш',
    'Acquirer': 'Эквайер',
    'NotSet': 'Не установлен',
    'NotConfirmedLine1': 'Электронная почта не подтверждена, проверьте почту',
    'NotConfirmedLine2': 'Или нажмите на кнопку для повторной отправки.',
    'NotConfirmedLine3': ' Пожалуйста, подтвердите свой email, чтобы получить доступ ко всем функциям.',
    'SessionExpired': 'Время сессии истекло',
    'SessionExpiredSubtitle': 'Возможно, вы вошли в систему с другого компьютера',
    'Charts': 'Графики',
    'Transactions': 'Операции',
    'General': 'Главная',
    'Invoice': 'Сформировать чек',
  },
  kz: {
    Validation: {
      'Required': 'Осы мәтінді толтырыңыз.',
      'IncorrectBIN': 'БСН енгізіңіз, тек нөмірге рұқсат етіледі.',
      'IncorrectIIK': 'IIC, сан немесе бас әріпті енгізуге рұқсат етіледі.',
      'IncorrectBIK': 'БСК енгізіңіз, тек бас латын әрпіне рұқсат етіледі',
      'EnterTitle': 'Атын енгізіңіз',
      'EnterHostname': 'Хост атын енгізіңіз',
      'EnterServerUrl': 'Сервер URL мекенжайын енгізіңіз',
      'EnterSuccessUrl': 'Сәтті операция өңдегішінің URL мекенжайын енгізіңіз',
      'EnterFailUrl': 'Қате өңдегішінің URL мекенжайын енгізіңіз',
      'EnterNetworks': 'Рұқсат етілген желілерді көрсетіңіз',
      'EnterPCommission': 'Комиссия мөлшерлемесін енгізіңіз. (тек сандар мен нүкте белгісі)',
      'EnterFCommission': 'Тұрақты төлем мөлшерлемесін енгізіңіз. (тек сандар мен нүкте белгісі)',
      'EnterPrivate': 'Жеке кілт мәнін енгізіңіз',
      'EnterOrderId': 'Тапсырыс идентификаторын енгізіңіз',
      'EnterAmount': 'Төлем сомасын енгізіңіз. (тек сандар мен нүкте белгісі)',
      'ChooseCurrency': 'Валютаны таңдаңыз',
      'EnterDescr': 'Сипаттама енгізіңіз.',
      'EnterEmail': 'Email енгізіңіз',
      'EnterName': 'Атыңызды енгізіңіз',
      'EnterPhone': 'Телефон нөмірін енгізіңіз',
      'EnterPwd': 'Құпия сөзді еңгізіңіз',
      'EnterPwdConfirm': 'Құпия сөзді растауды енгізіңіз',
    },
    'LogIn': 'Кіру',
    'Logout': 'Шығу',
    'AuthHdr': 'Platform: Сатушы кеңсесі',
    'EmailLbl': 'E-mail',
    'PwdLbl': 'Құпия сөз',
    'PwdCLbl': 'Құпия сөзді растауды',
    'EnterEmailLbl': 'Email енгізіңіз',
    'EnterPwdLbl': 'Құпия сөзді еңгізіңіз',
    'EnterPwdConfirmLbl': 'Құпия сөзді растауды енгізіңіз',
    'EmailValidateMsg': 'Өтінеміз қолданыстағы email енгізіңіз',
    'RememberMe': 'Мені есте сақтау',
    'Register': 'Тіркеу',
    'Login': 'Кіру',
    'ForgotPwdBtn': 'Құпия сөзіңізді ұмыттыңыз ба?',
    'SrvPoblemMsg': 'Серверде ақаулар бар. Кейінірек көріңіз',
    'NameLbl': 'Аты',
    'PhoneLbl': 'Телефон нөмірі',
    'SendEmailBtn': 'Поштаға жіберу',
    'IRememberedCB': 'Мен парольді есіме түсірдім!',
    'ReciverSentMsg': 'Сұрау жіберілді. Поштаңызды тексеріңіз',
    'BackBtn': 'Артқа',
    'EmailNeedToVerify': 'Email растауыңыз керек',
    'PageNotFound': 'Бұл бет табылмады',
    'PageNotExists': 'Сұралған бет жоқ, жылжытылған немесе жойылған. Сайт иесіне хабарласыңыз.',
    'GoToLogin': 'Авторизация бетіне өтіңіз',
    'App': 'Қосымша',
    'Apps': 'Қосымшалар',
    'PublicKey': 'Ашық кілт',
    'PrivateKey': 'Жеке кілт',
    'OrderId': 'ID Тапсырыс',
    'Amount': 'Сомасы',
    'Currency': 'Валюта',
    'CurrencyPH': 'Валютаны таңдаңыз',
    'Description': 'Сипаттама',
    'ErrorDescription': 'Қате сипаттамасы',
    'AppRedirectCB': 'Жасалғаннан кейін қайта бағыттау',
    'NewPaymentType': 'Жаңа төлем әдісін пайдалану',
    'UsePayout': 'Ақшаны алуға мүмкіндік береді',
    'Back': 'Артқа',
    'PayBtn': 'Төлеу',
    'PayOutBtn': 'Ақшаны алу',
    'Title': 'Тақырыбы',
    'Hostname': 'Хост аты',
    'ServerURL': 'Сервер URL мекенжайы',
    'ReturnURL': 'URL мекенжайын қайтару',
    'SuccessURL': 'Сәтті URL мекенжайы',
    'FailedURL': 'Қате URL',
    'TrustCreateKey': 'Менің кілтімді жасайтыныңызға сенемін',
    'DontTrustCreateKey': 'Мен сізге <b>сенбеймін</b> және кілтті өзім <a href="https://libsodium.gitbook.io/doc/bindings_for_other_languages" target="_blank">жасамын</a>.',
    'AllowedNetworks': 'Рұқсат етілген желілер',
    'UseIPMsg': '<b>192.168.1.1</b> сияқты IP мекенжайын пайдаланыңыз немесе желі маскасымен <b>192.168.1.0/24</b>, мекенжайларды үтірмен бөлу. Барлығына рұқсат беру үшін таңбаны пайдаланыңыз <b>*</b>.',
    'CommissionPercent': 'Пайыздық комиссия',
    'FixedCommission': 'Тұрақты комиссия',
    'UseTesting': 'Сынақ режимін пайдаланыңыз',
    'KeysDialogHdr': '<b>Назар аударыңыз!</b>Бұл терезені дереу жаппаңыз!',
    'KeysDialogLine1': 'Шифрлау кілттері серверде жасалғандықтан, олар ЕШ ЖЕРДЕ САҚТАЛМАЙДЫ.',
    'KeysDialogLine2': 'Терезені жаппас бұрын оларды сақтауды ұмытпаңыз.',
    'Address': 'Адрес',
    'BIN': 'БСН',
    'IIK': 'IIC',
    'BIK': 'БСК',
    'Update': 'Жаңарту',
    'Create': 'Жасау',
    'Size': 'Көлемі',
    'Actions': 'Әрекеттер',
    'AddFilesErrMsg': 'Сіз тек бұрын жасалған Ұйым үшін файлдарды қоса аласыз',
    'Documents': 'Құжаттар',
    'AddDocs': 'Құжаттарды қосыңыз',
    'MaxFilesMsg': 'Файлдардың максималды санына жетті',
    'PrivateKeyError': 'Қате жеке кілт',
    'AppUpdatedMsg': 'Қолданба жаңартылды',
    'IsNotValidUrl': 'Бұл қате URL мекенжайы',
    'EmptyNetworks': 'Рұқсат етілген желілер көрсетілмеген',
    'InvalidDataFormat': 'қате деректер пішімі',
    'OrganizationCreated': 'Ұйым құрылды',
    'NetworkError': 'Желі қатесі. Кейінірек көріңіз.',
    'InvalidAmount': 'Баланс 0-ден жоғары болуы керек',
    'OrganizationUpdated': 'Ұйым жаңартылды',
    'FileNotSupported': 'Жүктеп салынған файлға қолдау көрсетілмейді',
    'UplMaxSize': 'Максималды өлшем жүктелді',
    'or': 'немесе',
    'UploadError': 'Жүктеу қатесі: әрекетті кейінірек қайталаңыз.',
    'DeleteFileConfirm': 'Осы құжатты <b>жою</b> керек пе? Бұл әрекетті қайтару мүмкін емес.',
    'CanNotDeleteFile': 'Файл жойылмады',
    'DeletingDocument': 'Құжатты жою',
    'Applications': 'Қолданбалар',
    'NextPage': 'Келесі',
    'PreviousPage': 'Алдыңғы',
    'Page': 'Бет',
    'OrderID': 'OrderID',
    'CurrentPage': 'Ағымдағы',
    'AppTestingMode': 'Сынақ режиміндегі қолданба',
    'Percent': 'Пайыз',
    'Fixed': 'Түзету.',
    'Balance': 'Төлемдер балансы',
    'CardPan': 'Карта',
    'Edit': 'Өзгерту',
    'Delete': 'Жою',
    'AppKey': 'Қолданба кілті',
    'Copy': 'Көшіру',
    'TryApp': 'Сынақ төлемін жасау',
    'TryPayout': 'Сынақ ақшаны алу',
    'RequestProdLbl': 'Өндіріс режимін сұрау',
    'RequestProdBtn': 'Өндіріс режиміне сұраныс',
    'ReplenishBalance': 'Балансты толықтыру',
    'ReplenishBtn': 'Балансты толықтыруға сұраныс жіберу',
    'BalanceReplenished': 'Баланс толықтыру сұранысы қарастырылуға жіберілді',
    'OrgDreateNeedMsg': 'Алдымен сіз жасауыңыз керек',
    'OrgA': 'Ұйым',
    'Org': 'Ұйым',
    'NothingHere': 'Бос',
    'AppKeyCopied': 'Қолданба кілті көшірілді',
    'OrgNotSet': 'Ағымдағы ұйым орнатылмаған',
    'RequestProdSent': 'Өндіріс режиміне сұрау жіберілді',
    'DeletingAppHdr': 'Қолданба жойылуда',
    'DeletingAppMsg': 'Бұл қолданбаны <b>жою</b> керек пе? Бұл әрекетті қайтару мүмкін емес.',
    'DeletingAppConfirm': 'Қолданба жойылуда',
    'Dashboard': 'Dashboard',
    'StagesTitle': 'Серіктестікке өтініш беру қадамдары',
    'FillOutAppFrm': 'Өтініш формасын толтырыңыз',
    'CreateAppIn': 'Жаңа қолданба жасаңыз',
    'AppsSection': 'Қолданбалар бөлімінде',
    'CarryOutIntegr': 'Интеграцияны аяқтаңыз',
    'CheckOutOur': 'Зерттеу',
    'APIDocs': 'API құжаттамасын',
    'MakeIntegr': 'Интеграцияны аяқтаңыз',
    'FillOutOrgFrm': 'Ұйымдастыру формасын толтырыңыз',
    'CreateOrgIn': 'Жаңа Ұйымды жасаңыз',
    'OrgSection': 'Ұйым бөлімінде',
    'ActivateProd': 'Өндіріс режимін іске қосыңыз',
    'SendRequestOn': 'Қолданбаны өндіріс режиміне көшіру сұрауын жіберу үшін көрсеткіні басу керек',
    'SelectOrgForRequest': 'Тізімнен бұрын жасалған Ұйымды таңдап, «Өндіріс режимін сұрау» түймесін басыңыз.',
    'AllApps': 'Барлық қолданбалар',
    'Deleted': 'Жойылған',
    'NeedToVerifyEmail': 'Email растауыңыз керек',
    'Success': 'Сәтті',
    'Cancel': 'Болдырмау',
    'Fail': 'Ақау',
    'Errors': 'Қателер',
    'EnailVFail': 'Email растау қатесі',
    'VerifyExpired': 'Қате немесе мерзімі өткен қол қою. Қайтадан растауды сұраңыз.',
    'PaymentDetails': 'Төлем мәліметтері',
    'PaymentHash': 'Төлем хэші',
    'AcquirerId': 'Эквайер ID',
    'TerminalId': 'Терминал ID',
    'CardNumber': 'Карта нөмірі',
    'CardholderName': 'Иесінің аты',
    'Email': 'Email',
    'Phone': 'Телефон',
    'TotalAmount': 'Жалпы сома',
    'TotalCommission': 'Комиссияның жалпы сомасы',
    'Commissions': 'Комиссиялар',
    'CommissionType': 'Комиссия түрі',
    'Inner': 'Ішкі.',
    'Outer': 'Сыртқы.',
    'SystemCommission': 'Бағдарламалық қамтамасыз ету комиссиясы',
    'AppCommission': 'Қолданбау комиссиясы',
    'Status': 'Күй',
    'AutoClearing': 'Автоматты тазалау',
    'Yes': 'Иә',
    'No': 'Жоқ',
    'Lang': 'Тіл',
    'CreatedAt': 'Құрылған күні',
    'UpdatedAt': 'Жаңарту күні',
    'Payments': 'Төлемдерді қабылдау',
    'Payouts': 'Ақшаны алу',
    'ClearFilter': 'Сүзгі тазалау',
    'Download': 'Жүктеп алу',
    'DownloadCSV': 'Форматта жүктеп алыңыз.CSV',
    'DownloadXLSX': 'Форматта жүктеп алыңыз .XLSX',
    'AllStatuses': 'Барлық күйлер',
    'Created': 'Құрылды',
    'Canceled': 'Бас тартылды',
    'InProcess': 'Өңдеуде',
    'Authorized': 'Рұқсат етілген',
    'Completed': 'Аяқталды',
    'Reversed': 'Reversed',
    'Refunded': 'Қайтарылды',
    'CreatedFrom': 'Жасалған...',
    'CreatedTo': '... бастап',
    'Detail': 'Көбірек',
    'Hash': 'Хэш',
    'Acquirer': 'Эквайер',
    'NotSet': 'Орнатылмаған',
    'NotConfirmedLine1': 'Электрондық пошта расталмады, электрондық поштаңызды тексеріңіз',
    'NotConfirmedLine2': 'Немесе қайта жіберу үшін түймені басыңыз.',
    'NotConfirmedLine3': ' Барлық мүмкіндіктерге қол жеткізу үшін Email растаңыз.',
    'SessionExpired': 'Сессия уақыты бітті',
    'SessionExpiredSubtitle': 'Сіз басқа компьютерден кірген болуыңыз мүмкін',
    'Charts': 'Кестелер',
    'Transactions': 'Операциялар',
    'General': 'Басты',
    'Invoice': 'Чекті басып шығару',
  },
  en: {
    Validation: {
      'Required': 'Please fill out this field.',
      'IncorrectBIN': 'Please enter a BIN, only number is allowed.',
      'IncorrectIIK': 'Please enter an IIK, number or upper letter is allowed.',
      'IncorrectBIK': 'Please enter a BIK, only upper latin letter is allowed',
      'EnterTitle': 'Please enter a title',
      'EnterHostname': 'Please enter a hostname',
      'EnterServerUrl': 'Please enter a server URL',
      'EnterSuccessUrl': 'Please enter a success URL',
      'EnterFailUrl': 'Please enter a failed URL',
      'EnterNetworks': 'Please enter an allowed networks',
      'EnterPCommission': 'Please enter a commission percent, only number with dot is allowed',
      'EnterFCommission': 'Please enter a fixed commission, only number with dot is allowed',
      'EnterPrivate': 'Please enter a private key',
      'EnterOrderId': 'Please enter an order ID',
      'EnterAmount': 'Please enter an amount, only number with dot is allowed',
      'ChooseCurrency': 'Choose a currency',
      'EnterDescr': 'Please enter a description',
      'EnterEmail': 'Please enter an email',
      'EnterName': 'Please enter a name',
      'EnterPhone': 'Please enter a phone',
      'EnterPwd': 'Please enter a password',
      'EnterPwdConfirm': 'Please enter a password confirmation',
    },
    'LogIn': 'Log in',
    'Logout': 'Logout',
    'AuthHdr': 'Platform: Merchant panel',
    'EmailLbl': 'E-mail Address',
    'PwdLbl': 'Password',
    'PwdCLbl': 'Password confirmation',
    'EnterEmailLbl': 'Please enter an email',
    'EnterPwdLbl': 'Please enter a password',
    'EnterPwdConfirmLbl': 'Enter password confirmation',
    'EmailValidateMsg': 'Please enter a valid email address',
    'ResetPassword': 'Reset password',
    'RememberMe': 'Remember me',
    'Register': 'Register',
    'Login': 'Login',
    'ForgotPwdBtn': 'Forgot your password?',
    'SrvPoblemMsg': 'Problem with server try later',
    'NameLbl': 'Name',
    'PhoneLbl': 'Phone',
    'SendEmailBtn': 'Send email',
    'IRememberedCB': 'I remembered my password!',
    'ReciverSentMsg': 'Request sent. Check your email',
    'BackBtn': 'Back to login',
    'EmailNeedToVerify': 'You need to verify your email',
    'PageNotFound': 'Page Not Found',
    'PageNotExists': 'Requested page does not exists or has been moved or deleted. Please contact the site owner.',
    'GoToLogin': 'Go to Login page',
    'App': 'Application',
    'PublicKey': 'Public key',
    'PrivateKey': 'Private key',
    'OrderId': 'Order Id',
    'Amount': 'Amount',
    'Currency': 'Currency',
    'CurrencyPH': 'Choose a currency',
    'Description': 'Description',
    'ErrorDescription': 'Error description',
    'AppRedirectCB': 'Redirect after creation',
    'NewPaymentType': 'Use new payment method',
    'UsePayout': 'Use payout',
    'Back': 'Back',
    'PayBtn': 'Pay',
    'PayOutBtn': 'Payout',
    'Title': 'Title',
    'Hostname': 'Hostname',
    'ServerURL': 'Server URL',
    'ReturnURL': 'Return URL',
    'SuccessURL': 'Success URL',
    'FailedURL': 'Failed URL',
    'TrustCreateKey': 'I trust you to create a key',
    'DontTrustCreateKey': 'I <b>don\'t</b> trust you, I will <a href="https://libsodium.gitbook.io/doc/bindings_for_other_languages" target="_blank">create</a> myself.',
    'AllowedNetworks': 'Allowed Networks',
    'UseIPMsg': 'Use IP address <b>192.168.1.1</b> or netmask <b>192.168.1.0/24</b> specify separated by commas. To allow for everyone use <b>*</b>.',
    'CommissionPercent': 'Commission percent',
    'FixedCommission': 'Fixed commission',
    'UseTesting': 'Use testing mode',
    'KeysDialogHdr': '<b>Caution!</b> Don\'t close the window!',
    'KeysDialogLine1': 'Since the encryption keys were created on the server, they are NOT stored ANYWHERE.',
    'KeysDialogLine2': 'Be sure to save them before closing the window.',
    'Address': 'Address',
    'BIN': 'BIN',
    'IIK': 'IIK',
    'BIK': 'BIK',
    'Update': 'Update',
    'Create': 'Create',
    'Size': 'Size',
    'Actions': 'Actions',
    'AddFilesErrMsg': 'You can add files only on created organization',
    'Documents': 'Documents',
    'AddDocs': 'Add documents',
    'MaxFilesMsg': 'Maximum number of files reached',
    'PrivateKeyError': 'Private key is invalid',
    'AppUpdatedMsg': 'Application updated',
    'IsNotValidUrl': 'Is not valid URL',
    'EmptyNetworks': 'Empty allowed networks',
    'InvalidDataFormat': 'invalid data format',
    'OrganizationCreated': 'Organization created',
    'NetworkError': 'Network error, try later.',
    'InvalidAmount': 'Amount should be greater than 0',
    'OrganizationUpdated': 'Organization updated',
    'FileNotSupported': 'Uploaded file not supported',
    'UplMaxSize': 'Uploaded maximum size',
    'or': 'or',
    'UploadError': 'Upload error: try later',
    'DeleteFileConfirm': 'Are you sure you want to <b>delete</b> document? This action cannot be undone.',
    'CanNotDeleteFile': 'Can\'t delete file',
    'DeletingDocument': 'Deleting document',
    'Applications': 'Applications',
    'NextPage': 'Next page',
    'PreviousPage': 'Previous page',
    'Page': 'Page',
    'OrderID': 'OrderID',
    'CurrentPage': 'Current page',
    'AppTestingMode': 'Application is testing mode',
    'Percent': 'Percent',
    'Fixed': 'Fixed',
    'Balance': 'Payout balance',
    'CardPan': 'Card PAN',
    'Edit': 'Edit',
    'Delete': 'Delete',
    'AppKey': 'Application key',
    'Copy': 'Copy',
    'TryApp': 'Try the pay',
    'TryPayout': 'Try the payout',
    'RequestProdLbl': 'Request to prod mode',
    'RequestProdBtn': 'Request production',
    'ReplenishBalance': 'Replenish balance',
    'ReplenishBtn': 'Make request to replenish balance',
    'BalanceReplenished': 'Request made for replenish balance on the review',
    'OrgDreateNeedMsg': 'First you need to create an',
    'OrgA': 'organization',
    'Org': 'Organization',
    'NothingHere': 'Nothing here',
    'AppKeyCopied': 'Application key copied',
    'OrgNotSet': 'Current organization not set',
    'RequestProdSent': 'Request to production mode has been sent',
    'DeletingAppHdr': 'Deleting application',
    'DeletingAppMsg': 'Are you sure you want to <b>delete</b> application? This action cannot be undone.',
    'DeletingAppConfirm': 'Delete Application',
    'Dashboard': 'Dashboard',
    'StagesTitle': 'Stages of the Partnership Application',
    'FillOutAppFrm': 'Fill out the Application form',
    'CreateAppIn': 'Create your new application in the',
    'Apps': 'Applications',
    'AppsSection': 'Applications section',
    'CarryOutIntegr': 'Carry out the integration',
    'CheckOutOur': 'Check out our',
    'APIDocs': 'API documentation',
    'MakeIntegr': 'Make the integration',
    'FillOutOrgFrm': 'Fill out the Organization form',
    'CreateOrgIn': 'Create your new organization in the',
    'OrgSection': 'Organization section',
    'ActivateProd': 'Activate prod mode',
    'SendRequestOn': 'To send a request for transfer Application to prod mode, you must click on the arrow on the',
    'SelectOrgForRequest': 'Select a previously created organization from the list and click the "Request" button',
    'AllApps': 'All apps',
    'Deleted': 'Deleted',
    'NeedToVerifyEmail': 'You need to verify your email',
    'Success': 'Success',
    'Cancel': 'Cancel',
    'Fail': 'Fail',
    'Errors': 'Errors',
    'EnailVFail': 'Email verification fail',
    'VerifyExpired': 'Signature is invalid or has already expired. Please request verification again.',
    'PaymentDetails': 'Payment details',
    'PaymentHash': 'Payment hash',
    'AcquirerId': 'Acquirer id',
    'TerminalId': 'Terminal id',
    'CardNumber': 'Card number',
    'CardholderName': 'Cardholder name',
    'Email': 'Email',
    'Phone': 'Phone',
    'TotalAmount': 'Total amount',
    'TotalCommission': 'Total commission',
    'Commissions': 'Commissions',
    'CommissionType': 'Commission type',
    'Inner': 'Inner',
    'Outer': 'Outer',
    'SystemCommission': 'System commission',
    'AppCommission': 'Application commission',
    'Status': 'Status',
    'AutoClearing': 'Auto clearing',
    'Yes': 'Yes',
    'No': 'No',
    'Lang': 'Language',
    'CreatedAt': 'Created at',
    'UpdatedAt': 'Updated at',
    'Payments': 'Payments',
    'Payouts': 'Payouts',
    'ClearFilter': 'Clear filter',
    'Download': 'Download',
    'DownloadCSV': 'Download with .CSV file',
    'DownloadXLSX': 'Download with .XLSX file',
    'AllStatuses': 'All statuses',
    'Created': 'Created',
    'Canceled': 'Canceled',
    'InProcess': 'In process',
    'Authorized': 'Authorized',
    'Completed': 'Completed',
    'Reversed': 'Reversed',
    'Refunded': 'Refunded',
    'CreatedFrom': 'Created from',
    'CreatedTo': 'Created to',
    'Detail': 'Detail',
    'Hash': 'Hash',
    'Acquirer': 'Acquirer',
    'NotSet': 'Not set',
    'NotConfirmedLine1': 'Email is not confirmed, check your mail',
    'NotConfirmedLine2': 'Or click on the button to send again.',
    'NotConfirmedLine3': ' Please confirm your email to get access to all functionality.',
    'SessionExpired': 'Session expired',
    'SessionExpiredSubtitle': 'You may have signed in from a different computer',
    'Charts': 'Charts',
    'Transactions': 'Transactions',
    'General': 'General',
    'Invoice': 'Generate a check',
  },
}
