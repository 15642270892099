export default class User {
  public id: number;
  public name: string;
  public email: string;
  public phone: string;
  public payout: boolean;
  public emailVerifiedAt: Date | null = null;
  public deletedAt: Date | null = null;
  public createdAt: Date | null = null;
  public updatedAt: Date | null = null;
  public bannedAt: Date | null = null;

    constructor(user: any) {
        this.id = user.id;
        this.name = user.name;
        this.email = user.email;
        this.phone = user.phone;
        this.payout = user.payout;

        this.emailVerifiedAt = user.email_verified_at;
        this.deletedAt = user.deleted_at;
        this.createdAt = user.created_at;
        this.updatedAt = user.updated_at;
        this.bannedAt = user.banned_at;
    }

    public hasVerifiedEmail(): boolean {
      return !!this.emailVerifiedAt;
    }
}
