import Vue from 'vue';
import VueRouter, {Route} from 'vue-router';
import routes from '@/router/routes';
import store from '@/store';
import { sync } from 'vuex-router-sync';
import auth from '@/router/middleware/Authenticate';
import guest from '@/router/middleware/RedirectIfAuthenticated';
import verified from '@/router/middleware/Verified';

Vue.use(VueRouter);

const router = createRouter();
sync(store, router);

/**
 * Create a new router instance.
 *
 * @return {VueRouter}
 */
function createRouter() {
  const r = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'is-active',
  });

  r.afterEach(afterEach);
  r.beforeEach(beforeEach);

  return r;
}

const routeMiddleware: {[index: string]: any} = {
  auth,
  guest,
  verified,
};


/**
 *
 * @param {Route} to
 * @param {Route} from
 */
function afterEach(to: Route, from: Route) {
  store.dispatch('setLoading', false);
}

/**
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function beforeEach(to: Route, from: Route, next: any) {
  store.dispatch('setLoading', true);

  if (to.meta === undefined) {
    return next();
  }

  if (!to.meta.middleware) {
    return next();
  }

  to.meta.middleware.forEach((middleware: string) => {
    if (!routeMiddleware.hasOwnProperty(middleware)) {
      throw new Error(`Undefined middleware: ${middleware}`);
    }

    routeMiddleware[middleware]({from, next, store});
  });
}

export default router;
